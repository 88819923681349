import React from "react";
import GitHubButton from "react-github-btn";

export const GithubWidget = () => (
  <GitHubButton
    data-size="large"
    data-show-count="true"
  >
    Star
  </GitHubButton>
);
